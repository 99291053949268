<template>
    <div class="cyfw">
        <div class="left">
            <!-- 产业企业分布 -->
            <div class="pub_border top">
                <div class="pub_title">产业企业分布</div>
                <div class="content">
                    <ul>
                        <li>
                            <span @click="lookList(3, '化肥')"><i>{{ jdgkObj.fertilizer || 0 }}</i>家</span>
                            <img src="./../../assets/images/cyfw_bjjd.png" alt="">
                            <p>化肥企业</p>
                        </li>
                        <li>
                            <span @click="lookList(2, '农药')"><i>{{ jdgkObj.pesticides || 0 }}</i>家</span>
                            <img src="./../../assets/images/cyfw_bjjd.png" alt="">
                            <p>农药销售站点</p>
                        </li>
                        <li>
                            <span @click="lookList(5, '质检')"><i>{{ jdgkObj.testing || 0 }}</i>家</span>
                            <img src="./../../assets/images/cyfw_bjjd.png" alt="">
                            <p>检测机构</p>
                        </li>
                        <li>
                            <span @click="lookList(1, '种子种苗')"><i>{{ jdgkObj.seed || 0 }}</i>家</span>
                            <img src="./../../assets/images/cyfw_bjjd.png" alt="">
                            <p>种子种苗企业</p>
                        </li>
                        <li>
                            <span @click="lookList(4, '器械')"><i>{{ jdgkObj.apparatus || 0 }}</i>家</span>
                            <img src="./../../assets/images/cyfw_bjjd.png" alt="">
                            <p>生产器械</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 推荐商品 -->
            <div class="pub_border bottom">
                <div class="pub_title">推荐商品</div>
                <div class="content">
                    <ul>
                        <li v-for="(item, i) in goodsList" :key="item.name + i" @click="lookGoods()">
                            <div class="tjsp_left">
                                <img :src="tjImageUrl + item.imgUrl" @error="e => { e.target.src = defaultImg }" alt="">
                            </div>
                            <div class="tjsp_right">
                                <h3>{{ item.name }}</h3>
                                <p><span>供应商：</span><i class="gs" :title="item.gys">{{ item.gys }}</i><span>规格：</span><i
                                        :title="item.gg" class="gg">{{ item.gg }}</i></p>
                                <p><span>联系电话：</span>{{ item.tel }}</p>
                            </div>
                        </li>
                        <!-- <li>
                            <div class="tjsp_left">
                                <img src="./../../assets/images/jppp.png" alt="">
                            </div>
                            <div class="tjsp_right">
                                <h3>史丹利 复合肥蔬菜农用种菜</h3>
                                <p><span>供应商：</span>河北省安国市<span>规格：</span>300</p>
                                <p><span>联系电话：</span>15603114856</p>
                            </div>
                        </li>
                        <li>
                            <div class="tjsp_left">
                                <img src="./../../assets/images/jppp.png" alt="">
                            </div>
                            <div class="tjsp_right">
                                <h3>德沃多肥料</h3>
                                <p><span>供应商：</span>河北省安国市<span>规格：</span>300</p>
                                <p><span>联系电话：</span>15603114856</p>
                            </div>
                        </li>
                        <li>
                            <div class="tjsp_left">
                                <img src="./../../assets/images/jppp.png" alt="">
                            </div>
                            <div class="tjsp_right">
                                <h3>德沃多肥料</h3>
                                <p><span>供应商：</span>河北省安国市<span>规格：</span>300</p>
                                <p><span>联系电话：</span>15603114856</p>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="center">
            <plant-map ref="plantMapRef" has-drop height="90%" :chart-option="chartOption" @selectCity="mapSelect"
                @selectMarker="markerSelect"></plant-map>
            <!-- 图例 -->
            <div class="pub_legend">
                <div class="legend_title">图例</div>
                <ul class="legend_list">
                    <li v-for="item in legendData" :key="item.lablename">
                        <el-checkbox v-model="item.check" class="pub_checkbox" @change="selectChange"></el-checkbox>
                        <img :src="item.icon" alt=""> {{ item.lablename }}
                    </li>
                    <!-- <li>
                        <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
                        <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 化肥企业
                    </li>
                    <li>
                        <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
                        <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 种子种苗
                    </li>
                    <li>
                        <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
                        <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 农药销售
                    </li>
                    <li>
                        <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
                        <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 生产器械
                    </li>
                    <li>
                        <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
                        <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 检验检测
                    </li> -->
                </ul>
            </div>
        </div>
        <div class="right">
            <!-- 农资补贴政策 -->
            <div class="pub_border top">
                <div class="pub_title">农资补贴政策</div>
                <div class="content">
                    <ul v-infinite-scroll="reLoad" infinite-scroll-disabled="disabled" infinite-scroll-distance="10">
                        <li v-for="(item, i) in btzcList" :key="item.id + i">
                            <img src="./../../assets/images/cyfw_nz.png" alt="">
                            <span @click="lookArticle(item)" :title="item.title">{{ item.title }}</span>
                            <span>{{ item.publishTime }}</span>
                        </li>
                        <!-- <li>
                            <img src="./../../assets/images/cyfw_nz.png" alt="">
                            <span>河北最新通知：确保蔬菜生产稳定、供应充足</span>
                            <span>2022-05-01</span>
                        </li> -->
                    </ul>
                    <p v-if="loading">加载中...</p>
                    <!-- <p v-if="noMore">没有更多了</p> -->
                </div>
            </div>
            <!-- 销售动态 -->
            <div class="pub_border bottom">
                <div class="pub_title">销售动态</div>
                <div class="content">
                    <ul>
                        <li v-for="(item, i) in xsdtList" :key="i">
                            <span>{{ item.dw }}</span>
                            <img src="./../../assets/images/cg.png" alt="">
                            <p><span>{{ item.qy }}<i class="gys">{{ item.sc }}</i></span><span>{{ item.count
                            }}<i>吨</i></span></p>
                        </li>
                        <!-- <li>
                            <span>邯郸义保专业合作社邯郸义保专业合作社</span>
                            <img src="./../../assets/images/cg.png" alt="">
                            <p><span>xx种子企业 西红柿种苗</span><span>1005<i>株</i></span></p>
                        </li>
                        <li>
                            <span>邯郸义保专业合作社邯郸义保专业合作社</span>
                            <img src="./../../assets/images/cg.png" alt="">
                            <p><span>xx种子企业 西红柿种苗</span><span>1005<i>株</i></span></p>
                        </li>
                        <li>
                            <span>邯郸义保专业合作社邯郸义保专业合作社</span>
                            <img src="./../../assets/images/cg.png" alt="">
                            <p><span>xx种子企业</span><span>1005<i>株</i></span></p>
                        </li>
                        <li>
                            <span>邯郸义保专业合作社邯郸义保专业合作社</span>
                            <img src="./../../assets/images/cg.png" alt="">
                            <p><span>xx种子企业 西红柿种苗</span><span>1005<i>株</i></span></p>
                        </li>
                        <li>
                            <span>邯郸义保专业合作社邯郸义保专业合作社</span>
                            <img src="./../../assets/images/cg.png" alt="">
                            <p><span>xx种子企业xx种子企业xx种子企业</span><span>1005<i>株</i></span></p>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <!-- 企业详情 -->
        <Enterprise-info ref="enterpriseRef"></Enterprise-info>
        <!-- 农资商品页面 -->
        <goods-page ref="goodsPage"></goods-page>
        <!-- 企业列表 -->
        <enterprise-list ref="enterpriseList"></enterprise-list>
        <!-- 图文详情 -->
        <article-detail ref="articleDetail"></article-detail>
    </div>
</template>

<script>
import plantMap from '@/components/map/map'
import * as api from '@/api/cyfw' //接口
import { technologyPolicy } from '@/api/jsgc' //接口
// import { tjImageUrl } from '@/utils/config'
import GoodsPage from '@/components/goodsPage' //农资商品页面
import EnterpriseList from '@/components/enterpriseList' //企业列表
import ArticleDetail from '@/components/articleDetail' //图文详情
import EnterpriseInfo from '@/components/enterprise' //企业信息
export default {
    name: 'Cyfw',
    components: { plantMap, GoodsPage, EnterpriseList, ArticleDetail, EnterpriseInfo },
    data() {
        return {
            checked: true,
            // mapData:[],//地图数据
            jdgkObj: {},//产业企业分布
            iconData: {
                icon4: {//种子种苗
                    url: require('@/assets/images/cygk/jd_green_marker.png'),
                    size: [52, 66]
                },
                icon2: {//农药
                    url: require('@/assets/images/cygk/jd_red_marker.png'),
                    size: [52, 66]
                },
                icon1: {//化肥
                    url: require('@/assets/images/cygk/jd_marker.png'),
                    size: [52, 66]
                },
                icon5: {//器械
                    url: require('@/assets/images/cygk/jd_sc_marker.png'),
                    size: [31, 27]
                },
                icon3: {//质检
                    url: require('@/assets/images/cygk/jd_jc_marker.png'),
                    size: [21, 21]
                },
            },
            legendData: [//产业企业分布
                // {
                //     lablename:'',
                //     icon:'',
                //     check:true,
                // }
            ],
            areaCode: '',//区域
            tjImageUrl,
            goodsList: [//推荐商品
                {
                    name: '晶龙复合肥料',
                    gys: '河北晶龙丰利化工有限公司',
                    gg: '50kg',
                    tel: '13931235088',
                    imgUrl: '//client/inputgoods/showImg?img=/20200409/76965a5ed10145508d843afa8e60b0bd.jpg'
                },
                {
                    name: '春朝红 复合肥料',
                    gys: '河北春潮化肥有限公司',
                    gg: '50kg',
                    tel: '13931235088',
                    imgUrl: '//client/inputgoods/showImg?img=/20200401/41f7e2a6e4f248539576cffae75cf087.jpg'
                },
                {
                    name: '九阳复兴 复混肥料',
                    gys: '济宁海航生态肥业有限责任公司',
                    gg: '50kg',
                    tel: '13931235088',
                    imgUrl: '//client/inputgoods/showImg?img=/20200401/6bf1cefd11864a69908998f24198c45d.jpg'
                },
                {
                    name: '微生物菌剂',
                    gys: '青岛宏四方农业科技有限公司',
                    gg: '40kg',
                    tel: '13931235088',
                    imgUrl: '//client/inputgoods/showImg?img=/20200401/89d14d551df04bd0a4bba031927204fc.jpg'
                },
                {
                    name: '绿地康1号',
                    gys: '中农绿康',
                    gg: '500ml/瓶',
                    tel: '13932143414',
                    imgUrl: '//client/inputgoods/showImg?img=/20200330/35ea3aa1f3244347b2b6b5ff1ced86e0.jpg'
                },
                {
                    name: '微生物菌剂',
                    gys: '宁盾',
                    gg: '1升/桶',
                    tel: '13932143414',
                    imgUrl: '//client/inputgoods/showImg?img=/20200330/a268594919624ccfaa854b407432b028.jpg'
                },
            ],
            xsdtList: [//销售动态
                {
                    dw: '肖红玲',
                    qy: '庞晓昌',
                    type: 'gy',
                    sc: '西红柿',
                    count: 2,
                },
                {
                    dw: '张红林',
                    qy: '王红旗',
                    type: 'gy',
                    sc: '黄瓜',
                    count: 3,
                },
                {
                    dw: '洪建有',
                    qy: '张超',
                    type: 'gy',
                    sc: '芹菜',
                    count: 5,
                },
                {
                    dw: '燕晓波',
                    qy: '单东风',
                    type: 'cg',
                    sc: '红薯',
                    count: 6,
                },
                {
                    dw: '王猛飞',
                    qy: '许红斌',
                    type: 'cg',
                    sc: '红薯',
                    count: 12,
                },
                {
                    dw: '罗云鹏',
                    qy: '赵志中',
                    type: 'cg',
                    sc: '茄子',
                    count: 5,
                },
                {
                    dw: '魏立男',
                    qy: '申宪峰',
                    type: 'cg',
                    sc: '香菇',
                    count: 3,
                },
                {
                    dw: '王鹏辉',
                    qy: '庞瑞亭',
                    type: 'cg',
                    sc: '芹菜',
                    count: 5,
                },
                {
                    dw: '杜建军',
                    qy: '夏树辉',
                    type: 'cg',
                    sc: '红薯',
                    count: 3.6,
                },
            ],
            btzcList: [], //农资补贴政策
            currPage: 1,//当前页
            pageSize: 10,//每页条数
            totalPage: "",
            loading: false,
            defaultImg: require('@/assets/images/default_img.png'),

            goodsFlag: false,//商品弹框
            // qyFlag:false,//企业列表弹框
            articleFlag: false,//图文详情弹框
            chartOption: {
                tooltip: (params) => {
                    let data = params.data
                    let html = `<div style="padding:0;width: 280px;height: 90px;background: rgba(2,18,81,0.68);">
                            <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name}</div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系人：<span style="color:#0CFED9;font-size: 18px">${data.contacts || ''}</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系电话：<span style="color:#0CFED9;font-size: 18px">${data.phone || ''}</span></span></div>
                            <!--<div style="margin-left: 1em;line-height: 30px"><span>供应量：<span style="color:#0CFED9;font-size: 18px">${data.yield || 0}</span>吨</span></div>-->
                            <!--<div style="margin-left: 1em;line-height: 30px"><span>时间：<span style="color:#0CFED9;font-size: 18px">${data.plant_time ? data.plant_time.substr(0, 10) : ''}</span></span></div>-->
                        </div>`
                    return html
                }
            }
        }
    },
    computed: {
        noMore() {
            // 当起始页数大于等于总页数时停止加载
            return this.currPage >= Math.ceil(this.totalPage / this.pageSize)
        },
        disabled() {
            return this.loading || this.noMore;
        },
    },
    mounted() {
        this.industrySurvey()
        this.getLabelsInfo()
        this.technologyPolicy()
    },
    methods: {
        mapSelect(obj) {// 地图市区县选择
            // console.log('地图市区县选择')
            // console.log(JSON.parse(JSON.stringify(obj)))
            // this.currCityObj=obj
            if (obj.code || obj.code == '') {
                // console.log('地级市')
                this.areaCode = obj.code
                this.getLabelsInfo()
            } else {
                // console.log('县')
            }
        },
        industrySurvey(val) {//产业企业分布
            api.industrySurvey({}).then(res => {
                // console.log('产业企业分布')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                this.jdgkObj = res.data || {}
            })
        },
        technologyPolicy(val) {//农资补贴政策/产业政策
            technologyPolicy({
                pageSize: this.pageSize,
                pageNum: this.currPage,
            }).then(res => {
                // console.log('农资补贴政策')
                // console.log(JSON.parse(JSON.stringify(res.rows)))
                this.totalPage = res.total
                this.btzcList = [...this.btzcList, ...res.rows]
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        reLoad() {//农资补贴政策无限加载
            // console.log('农资补贴政策无限加载')
            //滑到底部时进行加载
            this.loading = true;
            setTimeout(() => {
                this.currPage += 1; //滚动条到底时，页码自增 1
                this.technologyPolicy(); //调用接口
            }, 500);
        },
        getLabelsInfo() {//地图
            api.getLabelsInfo({
                areaCode: this.areaCode
            }).then(res => {
                // console.log('地图')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                this.legendData = [];
                res.data.forEach((item, index) => {
                    let icon = this.iconData['icon' + (index + 1)]
                    if (icon) {
                        let data = item.data.map(subitem => {
                            return {
                                value: [subitem.longitude, subitem.latitude],
                                ...subitem
                            }
                        })
                        this.legendData.push({
                            lablename: item.lablename,
                            icon: icon.url,
                            check: true,
                            data,
                            size: icon.size,
                            url: 'image://' + icon.url,
                        })
                    }
                })
                // this.mapData=res.data||[]
                this.addMapMarker()
                console.log(this.legendData, 'fffffffffffff');
            })
        },
        selectChange(val) {//图例变化
            this.addMapMarker()
        },
        addMapMarker() {//地图上点
            let arr = []
            this.legendData.forEach(item => {
                if (item.check) {
                    arr.push({
                        name: item.lablename,
                        url: item.url,
                        size: item.size,
                        data: item.data
                    })
                }
            })
            if (arr.length != 0) {
                this.$refs.plantMapRef.addMarker(arr)
            } else {
                this.$refs.plantMapRef.addMarker([{
                    name: '',
                    url: '',
                    size: 40,
                    data: []
                }])
            }
        },
        // marker点击
        markerSelect(obj) {
            this.$refs.enterpriseRef.show(obj)
        },
        lookGoods() {//查看商品
            this.$refs.goodsPage.show()
        },
        lookList(val, title) {//查看企业列表
            let obj = {
                type: 1,
                param: {
                    // labelid:val,
                    typeName: title
                },
                title
            }
            this.$refs.enterpriseList.show(obj)
        },
        lookArticle(row) {//查看文章
            let obj = {
                title: row.title,
                createtime: row.publishTime,
                content: row.content
            }
            this.$refs.articleDetail.show(obj)
        },
    }
}
</script>
<style lang='scss' scoped>
.cyfw {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0 30px;
    background: url('~@/assets/images/cygk/cydx_bg.png') no-repeat center/100% 100%;

    .left {
        // border: 2px solid #0f0;
        width: 475px;
        flex: none;
        display: flex;
        flex-direction: column;

        .top {
            height: 280px;
            flex: none;
            // border: 2px solid #f00;
            display: flex;
            flex-direction: column;

            .content {
                flex: 1;

                // border: 2px solid #f00;
                >ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    box-sizing: border-box;

                    >li {
                        width: 120px;
                        // border: 2px solid #0f0;
                        text-align: center;
                        margin: 0 10px;

                        span {
                            color: #00FFFF;
                            font-size: 14px;
                            position: relative;
                            top: 15px;
                            cursor: pointer;

                            i {
                                font-size: 24px;
                            }
                        }

                        p {
                            font-size: 16px;
                            position: relative;
                            bottom: 5px;
                        }
                    }

                    li:nth-child(2) {
                        span {
                            color: #FFD800;
                        }
                    }

                    li:nth-child(4) {
                        span {
                            color: #32B4FF;
                        }
                    }

                    li:nth-child(4),
                    li:nth-child(5) {
                        margin-top: -10px;
                    }
                }
            }
        }

        .bottom {
            // border: 2px solid #0f0;
            margin-top: 20px;
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .content {
                padding: 20px 40px 0;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                flex: 1;

                >ul {
                    flex: 1;
                    overflow-y: auto;

                    >li {
                        background: url('~@/assets/images/zsk_border.png') no-repeat center/100% 100%;
                        display: flex;
                        align-items: center;
                        padding: 10px 15px;
                        box-sizing: border-box;
                        margin-top: 20px;
                        cursor: pointer;

                        .tjsp_left {
                            width: 81px;
                            height: 81px;
                            flex: none;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .tjsp_right {
                            flex: 1;
                            // border: 1px solid #0f0;
                            margin-left: 20px;

                            h3 {
                                font-size: 18px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 7px;
                            }

                            p {
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-top: 3px;

                                .gs {
                                    // border: 1px solid #0f0;
                                    display: inline-block;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                .gg {
                                    // border: 1px solid #0f0;
                                    display: inline-block;
                                    width: 50px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                span {
                                    color: #02CAFD;
                                }

                                span:nth-child(2) {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }

                    li:first-child {
                        margin-top: 0;
                    }
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    .center {
        // border: 2px solid #f0f;
        flex: 1;
        margin: 0 10px;
        position: relative;

        .pub_legend {
            right: 40px;
            bottom: 60px;

            .legend_list {
                padding: 10px;
                box-sizing: border-box;

                >li {
                    font-size: 16px;
                    // border: 1px solid #0f0;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    // padding-right: 0px;
                    box-sizing: border-box;
                    cursor: pointer;

                    .pub_checkbox {
                        margin-right: 10px;
                    }

                    img {
                        margin-right: 10px;
                        width: 21px;
                    }
                }

                // >li:nth-child(7){
                //     img{
                //         position: relative;
                //         left: 5px;
                //     }
                // }
            }
        }
    }

    .right {
        // border: 2px solid #ff0;
        width: 475px;
        flex: none;
        display: flex;
        flex-direction: column;

        .top {
            // border: 2px solid #f00;
            height: 280px;
            flex: none;
            display: flex;
            flex-direction: column;

            // overflow: hidden;
            .content {
                padding: 20px 30px 30px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow: hidden;

                >ul {
                    flex: 1;
                    // border:1px solid #0f0;
                    height: 170px;
                    overflow-y: auto;

                    // overflow: hidden;
                    >li {
                        // border:1px solid #0f0;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        padding: 0 20px;
                        box-sizing: border-box;
                        cursor: pointer;

                        img {
                            height: 21px;
                            // border:1px solid #0f0;
                        }

                        span {
                            font-size: 16px;
                            display: inline-block;
                            cursor: pointer;
                        }

                        span:nth-child(2) {
                            color: #fff;
                            // border:1px solid #f0f;
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-right: 10px;
                            margin-left: 10px;
                        }

                        span:nth-child(3) {
                            color: #2CC6FF;
                            // border:1px solid #0ff;
                            width: 90px;
                            flex: none;
                        }
                    }

                    li:first-child {
                        margin-top: 0;
                    }

                    li:nth-child(even) {
                        background-color: rgba(21, 78, 149, .3);
                    }
                }

                p {
                    font-size: 16px;
                    text-align: center;
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .bottom {
            // border: 2px solid #ff0;
            margin-top: 20px;
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .content {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 10px;
                box-sizing: border-box;
                overflow: hidden;

                >ul {
                    flex: 1;
                    overflow-y: auto;

                    >li {
                        background: url('~@/assets/images/xsdt_bg.png') no-repeat center/100% 47px;
                        height: 81px;
                        display: flex;
                        align-items: center;
                        padding: 0 50px;
                        box-sizing: border-box;

                        // border: 1px solid #0f0;
                        img {
                            width: 80px;
                            height: 81px;
                        }

                        >span {
                            // border: 1px solid #f00;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            // width: 150px;
                            width: 70px;
                            display: inline-block;
                            flex: none;
                            font-size: 16px;
                        }

                        p {
                            // border: 1px solid #f00;
                            display: flex;
                            align-items: center;
                            flex: 1;
                            overflow: hidden;

                            span {
                                font-size: 16px;
                            }

                            span:nth-child(1) {
                                display: inline-block;
                                // flex: 1;
                                // width: 120px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-left: 10px;

                                .gys {
                                    display: inline-block;
                                    width: 50px;
                                    // border: 1px solid #0f0;
                                    margin-left: 10px;
                                }
                            }

                            span:nth-child(2) {
                                color: #00FFFF;
                                display: inline-block;
                                flex: none;
                                font-size: 20px;
                                margin-left: 10px;

                                i {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
</style>
