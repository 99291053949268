import { request } from '@/utils/axios'

//产业服务-供京基地概况
export const industrySurvey = (params) => {
    return request({
        method: 'get',
        url: sysUrl + '/web/IndustrySurvey/list',
        params
    })
}
//产业服务-地图
export const getLabelsInfo = (params) => {
    return request({
        method: 'get',
        url: sysUrl + '/web/comAndLabRelation/getLabelsInfo',
        params
    })
}